$backdrop-color: rgba(0, 0, 0, 0.8);
$button-bg: rgba(0, 0, 0, 0.5);
$active-dot-color: #000;
$inactive-dot-color: #ccc;

.b-whats-new-info-wrapper {
  max-width: 1200px;
  margin: auto;
  width: 100%;

  h1 {
    color: #f37234;
  }

  @media screen and (max-width: 1440px) {
    max-width: 1200px;
  }

  @media screen and (max-width: 1024px) {
    max-width: calc(100% - 40px);
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    gap: 20px;

    button {
      color: #125E9E;
      text-decoration: none;
      font-weight: 500;
      transition: color 0.3s;
      font-size: 1.1rem;
      position: relative;
      background: none;
      border: none;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 0;
        height: 4px;
        background-color: #fdc12a;
        border-radius: 1rem;
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease-in-out;
      }

      &:hover {
        color: #F37539;

        &::before {
          background-color: #f37234;
          width: 2rem;
          opacity: 1;
          visibility: visible;
        }
      }

      &.active {
        color: #125E9E;

        &::before {
          width: 2rem;
          opacity: 1;
          visibility: visible;
          background-color: #fdc12a;
        }

        &:hover {
          color: #F37539;

          &::before {
            background-color: #f37234;
          }
        }
      }

      &:focus {
        outline: none;
      }
    }
  }

  .video-list{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .video-item{
      width: 32%;
      iframe{
        width: 100%;
        height: 250px;
        border-radius: 8px;
      }
    }
  }

  .masonry {
    column-count: 3;
    column-gap: 16px;
    width: 100%;
    margin-top: 30px;

    .masonry-item {
      break-inside: avoid;
      margin-bottom: 16px;
      cursor: pointer;

      img {
        width: 100%;
        display: block;
        border-radius: 8px;
      }
    }
  }
}