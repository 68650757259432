.b-footer {
    margin-top: 100px;

    .b-width-wrapper {

        flex-direction: column;

        .upper-part {
            border-top: 3px solid #C9E2F8;
            width: 100%;
            padding-bottom: 30px;
            display: flex;
            justify-content: space-evenly;

            .col-1 {

                display: flex;
                flex-direction: column;
                width: 100%;

                h3 {
                    color: #494949;
                    margin-bottom: 30px;
                }

                img {
                    width: 25px;
                    height: 25px;
                }

                .details {
                    width: 100%;
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    margin-bottom: 25px;

                    .content {

                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: 5px;

                        .title {
                            font-weight: 800;
                            color: #494949;
                            font-size: 0.95rem;
                            margin-bottom: 4px;
                        }

                        .data {
                            font-weight: 300;
                            color: #5C5C5C;
                            font-size: 0.9rem;
                        }

                        .mail-link {
                            text-decoration: none;
                        }

                        .data,
                        .title {
                            margin: 0px;
                        }
                    }
                }
            }

            .col-2 {

                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                h3 {
                    color: #494949;
                    margin-bottom: 30px;
                }

                .links {
                    display: flex;
                    gap: 35px;

                    .right,
                    .left {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        a {
                            position: relative;
                            color: #125E9E;
                            text-decoration: none;
                            padding-left: 20px;
                            transition: color 0.3s ease, padding-left 0.3s ease;

                            &:before {
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: 50%;
                                width: 10px;
                                height: 2px;
                                background-color: #125E9E;
                                transition: width 0.3s ease, background-color 0.3s ease;
                            }

                            &:hover {
                                color: #f37234;
                                padding-left: 25px;

                                &:before {
                                    width: 20px;
                                    background-color: #f37234;
                                }
                            }
                        }
                    }
                }
            }

            .col-3 {

                width: 70%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                h3 {
                    color: #494949;
                    margin-bottom: 30px;
                }

                .social-links {

                    display: flex;
                    gap: 15px;

                    .link-1,
                    .link-2,
                    .link-3,
                    .link-4 {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 2px;
                        border-radius: 50%;
                        width: 36px;
                        height: 36px;
                        cursor: pointer;
                        transition: transform 0.3s ease-in-out;

                        &:hover {
                            transform: translateY(-5px);
                        }

                        img {
                            width: 18px;
                            height: 18px;
                        }
                    }

                    .link-1 {
                        border: 2px solid #1877F2;
                    }

                    .link-2 {
                        border: 2px solid #2cb642;
                    }

                    .link-3 {
                        border: 2px solid #ee2a7b;

                    }

                    .link-4 {
                        border: 2px solid #FF0000;
                    }
                }
            }
        }

        .lower-part {
            display: flex;
            text-align: center;
            width: 100%;
            justify-content: center;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .b-footer {
        .b-width-wrapper {
            .upper-part {
                .col-1 {
                    .details {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 15px;
                    }
                }

                .col-2 {
                    .links {
                        flex-direction: column;
                        gap: 15px;

                        .right,
                        .left {
                            align-items: center;
                            gap: 15px;

                            a {
                                width: 55%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 440px) {
    .b-footer {
        .b-width-wrapper {
            .upper-part {
                flex-direction: column;

                .col-1 {
                    .details {
                        .content {
                            .title {
                                font-size: 0.8rem;
                            }

                            .data {
                                font-size: 0.75rem;
                            }
                        }
                    }
                }

                .col-2 {
                    align-items: flex-start;

                    .links {
                        flex-direction: row;
                        width: 100%;

                        .right,
                        .left {
                            a {
                                font-size: 0.8rem;
                                width: 100%;
                            }
                        }
                    }
                }

                .col-3 {
                    align-items: flex-start;
                    .social-links {

                        .link-1,
                        .link-2,
                        .link-3,
                        .link-4 {
                            width: 30px;
                            height: 30px;

                            img {
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}