.b-tribute-wrapper{

    .b-width-wrapper{
       margin-top: 30px;
       align-items: center;
        display: flex;
        gap: 30px;
        .b-tribute-content{
            h1{
                color: #494949;
                width: 65%;

                span{
                    color: #f37234;
                }
            }
            p{
                color: #494949;
                text-align: justify;
            }
        }

        .image{
            img{
                width: 100%;
                height: 100%;
                border-radius: 20px;
            }
        }
    }
}

@media only screen and (max-width: 820px){
    .b-tribute-wrapper{
        .b-width-wrapper{
            flex-direction: column;
            gap: 20px;
            .b-tribute-content{
                h1{
                    width: 100%;
                }
            }
        }
    }
}