.b-event-detail-wrapper {
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .images {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;
            gap: 20px;

            img {
                width: 250px;
                height: 200px;
                border-radius: 20px;
            }
        }
    }
}