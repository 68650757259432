.b-videos-wrapper {
    .b-width-wrapper {
        flex-direction: column;

        .head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;

            h3 {
                color: #ff6b00;
                font-size: 2.5rem;
                margin: 0;
            }

            p {
                margin: 0;
            }
        }

        .video-list {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            margin-top: 30px;

            .video-item {
                width: 32%;

                @media screen and (max-width: 1024px) {
                    width: 48%;
                }

                @media screen and (max-width: 440px) {
                    width: 100%;
                }

                iframe {
                    width: 100%;
                    height: 250px;
                    border-radius: 8px;
                    margin-bottom: 10px;
                }

                a {
                    text-decoration: none;
                    color: #494949;
                    transition: 0.3s;

                    &:hover {
                        color: #f37234;
                    }
                }
            }
        }

        .load-more-btn {
            width: fit-content;
            background-color: #145e9d;
            padding: 0.75rem 1rem;
            font-size: 1rem;
            color: #fff;
            font-weight: 500;
            border-radius: 1rem;
            border: none;
            cursor: pointer;
            transition: 0.5s ease-in-out;

            &:hover {
                background-color: #f37234;
            }
        }
    }
}