.b-terms-wrapper{

    .content{
        flex-direction: column;

        h2{
            font-size: 1.5rem;
            text-align: center;
            color: #494949;
            font-size: 35px;
        }

        .data{
            display: flex;
            flex-direction: column;

            h3{
                font-size: 25px;
                color: #494949;
                margin: 10px 0px 0px 0px;
            }

            p{
                color: #494949;
                text-align: justify;
                line-height: 26px;

                a{
                    text-decoration: none;
                    color: #f37234;
                    font-weight: bold;
                }
            }
        }
    }
}
