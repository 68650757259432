.b-badge-wrapper {
    .content {
        flex-direction: column;
        align-items: center;

        h2 {
            color: #494949;
            font-size: 40px;
            text-align: center;
            width: 70%;
        }

        img {
            width: 50%;
            height: auto;
            border-radius: 20px;
            margin-top: 30px;
        }

        .data {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-top: 100px;

            .col-1,
            .col-2,
            .col-3 {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 30px;

                .box {
                    width: auto !important;
                    gap: 20px;
                    display: flex;
                    background-color: #fff;
                    border-radius: 1rem;
                    padding: 2rem;
                    text-align: center;
                    box-shadow: 0px 0px 0.5rem 0 #c9e2f8;
                    transition: 0.3s;

                    &:hover {
                        box-shadow: 0px 0px 0.5rem 0.2rem #c9e2f8;
                    }

                    img {
                        width: 50px;
                        height: 50px;
                        border-radius: 20px;
                        margin: 0px;
                    }

                    p {
                        color: #000;
                        margin: 0px;
                        text-align: justify;
                    }

                    .circle {
                        padding: 15px;
                        background-color: #fff;
                        width: fit-content;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
