.b-about-wrapper {
  margin-top: 80px;

  .content {

    margin-top: -10px;
    padding: 30px 0px 20px 0px;

    .b-width-wrapper {

      flex-direction: column;

      .section-1 {
        display: flex;
        gap: 40px;
        margin-top: -100px;

        .left-side {
          width: 100%;
          display: flex;
          flex-direction: column;

          h2 {
            color: #494949;

            span {
              color: #F37234;
            }
          }

          p {
            color: #494949;
            font-weight: 300;
            line-height: 26px;
            text-align: justify;
          }

          @keyframes expandWidthSkills {
            from {
              width: 0;
            }

            to {
              width: 80%;
            }
          }

          @keyframes expandWidthScience {
            from {
              width: 0;
            }

            to {
              width: 70%;
            }
          }

          @keyframes fadeInText {
            from {
              opacity: 0;
            }

            to {
              opacity: 1;
            }
          }

          .skills,
          .science {
            background-color: #eee;
            border-radius: 20px;
            width: 60%;
            margin-top: 20px;
            overflow: hidden;

            .bar {
              height: 20px;
              padding: 12px 14px;
              border-radius: 20px 10px 10px 20px;
              display: flex;
              color: #fff;
              overflow: hidden;
              width: 0%;
              animation: expandWidth 2s forwards;

              &::before {
                content: attr(data-text);
                display: block;
                opacity: 0;
                animation: fadeInText 0.5s forwards 0.5s;
              }
            }
          }

          .skills {
            .bar {
              background-color: #8560ce;
              animation: expandWidthSkills 2s forwards;
            }
          }

          .science {
            .bar {
              background-color: #51bd87;
              animation: expandWidthScience 2s forwards;
            }
          }

        }

        .right-side {
          width: 100%;

          img {
            width: 100%;
            border-radius: 20px;
            object-fit: cover;
            height: 400px;
          }
        }
      }

      .section-2 {
        display: flex;
        margin-top: 100px;

        .image-side {
          width: 100%;

          img {
            width: 80%;
            object-fit: cover;
            height: auto;
          }
        }

        .text-side {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          h2 {
            color: #494949;
            margin: 0px;
          }

          p {
            color: #494949;
            text-align: justify;
            line-height: 26px;
          }
        }
      }

      .section-3 {
        margin-top: 30px;

        h3 {
          color: #494949;
        }

        p {
          color: #494949;
          text-align: justify;
          line-height: 26px;
        }
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .b-about-wrapper {
    .content {
      .b-width-wrapper {
        .section-1 {
          flex-direction: column;

          .left-side {
            width: 100%;

            .skills,
            .science {
              width: 100%;
            }
          }
        }

        .section-2 {
          flex-direction: column;

          .image-side {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 50%;
              height: auto;
            }
          }

          .text-side {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h2 {
              text-align: center;
            }

            p {
              text-align: center;
            }
          }
        }
      }
    }
  }
}