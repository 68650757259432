.b-document-wrapper {
    .b-width-wrapper {
        padding-bottom: 50px;

        .content {
            display: flex;
            flex-direction: column;

            h1 {
                color: #494949;

                span {
                    color: #FF6C44;
                }
            }

            ol {
                display: flex;
                flex-direction: column;
                gap: 20px;

                li {


                    a {
                        text-decoration: none;
                        color: #494949;
                        position: relative;
                        display: inline-block;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 0;
                            height: 2px;
                            bottom: -5px;
                            left: 50%;
                            background-color: #494949;
                            transition: width 0.3s ease, left 0.3s ease;
                        }

                        &:hover::after {
                            width: 100%;
                            left: 0;
                        }
                    }


                    &::marker {
                        color: #FF6C44;
                    }
                }
            }
        }
    }
}