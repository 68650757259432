$backdrop-color: rgba(0, 0, 0, 0.8);
$button-bg: rgba(0, 0, 0, 0.5);
$active-dot-color: #000;
$inactive-dot-color: #ccc;

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $backdrop-color;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.container__slider {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  background: #fff;
  overflow: hidden;
  border-radius: 10px;

  .slider__close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    z-index: 1001;
  }

  .slider__item {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease;

    &.slider__item-active {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
    }

    img {
      width: auto;
      height: 100vh;

    }
  }

  .container__slider__links {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;

    .container__slider__links-small {
      background: $inactive-dot-color;
      border: none;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      cursor: pointer;

      &.container__slider__links-small-active {
        background: $active-dot-color;
      }
    }
  }

  .slider__btn-next,
  .slider__btn-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: $button-bg;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;

    &.slider__btn-next {
      right: 10px;
    }

    &.slider__btn-prev {
      left: 10px;
    }
  }
}
