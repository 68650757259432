.b-principal-wrapper {
    .b-width-wrapper {
        .section-1 {
            display: flex;
            gap: 15px;

            .left-side {
                width: 100%;

                h2 {
                    color: #494949;

                    span {
                        color: #F37234;
                    }
                }

                h3,
                p {
                    color: #494949;
                }

                p {
                    text-align: justify;
                    font-weight: 400;
                    line-height: 26px;
                }
            }

            @keyframes fadeUp {
                from {
                    opacity: 0;
                    transform: translateY(50px); 
                }
                to {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
            

            .right-side {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    animation: fadeUp 1s ease-out forwards; 
                }
            }
        }
    }
}

@media only screen and (max-width: 820px){
    .b-principal-wrapper {
        .b-width-wrapper {
            .section-1 {
                flex-direction: column;

                .left-side {
                    width: 100%;

                    h2 {
                        font-size: 1.5rem;
                    }

                    h3 {
                        font-size: 1.2rem;
                    }

                    p {
                        font-size: 1rem;
                    }
                }

                .right-side {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}