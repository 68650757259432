.b-contact-wrapper{

    .content{
        flex-direction: column;

        h2{
            color: #f37234;
            font-size: 45px;
            text-align: center;
        }

        .boxes{
            display: flex;
            gap: 20px;

            .box{
                width: 100%;
                padding: 2rem;
                border-radius: 1rem;
                color: #fff;
                font-weight: 500;

                a{
                    text-decoration: none;
                    color: #fff;
                }
            }

            .location{
                background: linear-gradient(to left, #56ab2f, #a8e063);
            }

            .mail{
                background: linear-gradient(to right, #00c6ff, #0072ff);
            }

            .phone{
                background: linear-gradient(to right, #f953c6, #b91d73);
            }
        }

        iframe{
            width: 100%;
            height: 500px;
            border: none;
            border-radius: 20px;
            margin-top: 30px;
        }
    }
}
