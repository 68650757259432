@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  margin: 0px;
}

.b-width-wrapper {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    justify-content: inherit;
    align-items: inherit;

    @media screen and (max-width: 1440px) {
        max-width: 1200px;
    }

    @media screen and (max-width: 1240px) {
        max-width: calc(100% - 40px);

        .width-wrapper {
            max-width: 100%;
        }
    }
}