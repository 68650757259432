.b-moto-wrapper {
    margin-top: 40px;

    .section-1 {
        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;

            h2{
                font-size: 32px;
                color: #494949;

                q{
                    color: #f37234;
                }
            }

            .data {
                display: flex;
                gap: 30px;

                .text {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    width: 100%;

                    h3 {
                        margin: 0px;
                        color: #494949;
                        font-size: 25px;
                    }

                    p {
                        font-weight: 400;
                        color: #494949;
                        line-height: 26px;
                        text-align: justify;
                        margin: 0px;
                    }
                }

                .image {
                    width: 100%;

                    img {
                        width: 100%;
                        border-radius: 20px;
                    }
                }
            }
        }
    }

    .section-2{
        margin-top: 30px;
        .content{
            flex-direction: column;

            h2{
                color: #494949;
            }

            p{
                color: #494949;
                text-align: justify;
                line-height: 26px;
                font-weight: 400;
            }
        }
    }
}