.b-blog-wrapper {

    .wrapper {
        display: flex;
        flex-direction: column;
        h1 {
            color: #ff6b00;
            text-align: left;
            font-size: 2.5rem;
            margin: 20px 0;
        }

        .b-blogs-containers {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .card {
                width: 30%;
                min-width: 30%;
                border: 1px solid #f1f1f1;
                border-radius: 1rem;
                padding: 1rem;
                transition: all 0.3s ease;

                @media screen and (max-width: 1024px) {
                    width: 45%;
                    min-width: 48%;
                }

                @media screen and (max-width: 500px) {
                    width: 90%;
                    min-width: 90%;
                }

                &:hover {
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                }

                .card-img-wrapper {
                    img {
                        width: 100%;
                        object-fit: cover;
                        height: 250px;
                        border-radius: 16px;
                    }
                }

                .card-body {

                    .card-title {
                        font-size: 1.5rem;
                        font-weight: 600;
                        margin: 1rem 0;
                        text-transform: capitalize;
                    }

                    .card-text {
                        font-size: 1rem;
                        color: #494949;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: auto;
                        margin-bottom: 20px;
                    }

                    a {
                        text-decoration: none;
                        color: #3F7BA3;
                        text-transform: capitalize;
                        transition: all 0.3s ease;
                        font-size: 0.825rem;

                        &.color{
                            color: #494949;
                        }

                        &:hover {
                            color: #ff6b00;
                        }
                    }
                }
            }
        }
    }
}