@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

$primary-color: #f37335;
$secondary-color: #fdc12a;
$text-color: #494949;
$border-radius: 1rem;
$box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

.b-home-wrapper {
    .b-wrapper {
        .content {
            width: 100%;
            display: flex;
            gap: 30px;
            height: 100%;

            .left-side {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                h1 {
                    font-size: 4rem;
                    font-weight: 700;
                    color: $text-color;
                    margin: 0;

                    span {
                        color: $primary-color;
                    }
                }

                p {
                    border-left: 4px solid $primary-color;
                    padding-left: 30px;
                    margin-top: 20px;
                    color: $text-color;
                    line-height: 1.5rem;
                }
            }

            .right-side {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                position: relative;
                padding: 20px;
                box-sizing: border-box;

                .circle {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    position: absolute;
                }

                .circle-1 {
                    @extend .circle;
                    height: 125px;
                    width: 125px;
                    background-color: $primary-color;
                    margin: 10px;
                    right: 50px;
                    top: 0;
                }

                .circle-2 {
                    @extend .circle;
                    height: 50px;
                    width: 50px;
                    background: linear-gradient(to top, $primary-color, $secondary-color);
                    margin: 10px;
                    top: 150px;
                }

                .circle-3 {
                    @extend .circle;
                    width: 80px;
                    height: 80px;
                    background-color: $secondary-color;
                    margin: 10px;
                    left: 0;
                    top: 250px;
                }

                .circle-4 {
                    @extend .circle;
                    width: 40px;
                    height: 40px;
                    background: linear-gradient(to bottom, $primary-color, $secondary-color);
                    margin: 10px;
                    top: 450px;
                    left: 200px;
                }

                img {
                    border-radius: 50%;
                    object-fit: cover;
                    opacity: 0;
                    transform: translateY(100%);
                    animation: fadeUp 1s ease-out forwards;
                }

                .image-1 {
                    height: 250px;
                    width: 250px;
                    position: absolute;
                    left: 60px;
                    top: 0;
                }

                .image-2 {
                    width: 200px;
                    height: 200px;
                    position: absolute;
                    right: 0;
                    top: 200px;
                }

                .image-3 {
                    width: 150px;
                    height: 150px;
                    left: 150px;
                    position: absolute;
                    top: 300px;
                }

                .image-4 {
                    width: 100px;
                    height: 100px;
                    position: absolute;
                    top: 450px;
                    right: 180px;
                }
            }
        }

        .about {
            margin-top: 150px;

            svg {
                margin-top: -150px;
            }

            .background {
                background-color: #f4fcff;
                height: 100%;
                margin-top: -5px;
                z-index: 0;
                width: 100%;

                .content {
                    width: 100%;
                    margin-top: -50px;
                    display: flex;
                    flex-direction: column;

                    .heading {
                        width: 100%;
                        text-align: center;

                        h1 {
                            color: $text-color;
                            width: 100%;
                            font-size: 2.5rem;

                            span {
                                color: $primary-color;
                            }
                        }

                        p {
                            font-size: 1.2rem;
                            font-weight: 300;
                        }
                    }

                    .about-boxes {
                        display: flex;
                        gap: 20px;
                        align-items: center;

                        .box,
                        .big-box {
                            opacity: 0;
                            transform: translateY(100%);
                            transition: opacity 1.5s, transform 1.5s;
                        }

                        .box.visible,
                        .big-box.visible {
                            opacity: 1;
                            transform: translateY(0);
                        }

                        .big-box,
                        .box {
                            width: 33.33%;
                            color: $text-color;
                            background-color: #fff;
                            border-radius: 2rem;
                            padding: 3rem;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            box-shadow: $box-shadow;
                            transition: 0.3s;

                            &:hover {
                                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
                            }

                            img {
                                border-radius: 50%;
                            }
                        }

                        .big-box img {
                            width: 7rem;
                            height: 7rem;
                            object-fit: cover;
                        }

                        a {
                            border: 2px solid $primary-color;
                            border-radius: 2rem;
                            color: $primary-color;
                            font-size: 1rem;
                            font-weight: 500;
                            padding: 0 1rem;
                            height: 2.75rem;
                            display: flex;
                            align-items: center;
                            transition: 0.5s;
                            text-decoration: none;

                            &:hover {
                                background: linear-gradient(to bottom, $primary-color, $secondary-color);
                                color: #fff;
                            }
                        }

                        .box img {
                            width: 3rem;
                            height: 3rem;
                        }
                    }
                }
            }
        }

        .events,
        .classes,
        .gallery {
            background-color: #f4fcff;

            .b-width-wrapper {
                padding-top: 80px;
                gap: 30px;

                .left-images {
                    width: 100%;
                    height: 100%;

                    .upper-side {
                        h1 {
                            font-size: 2.5rem;
                            color: $text-color;

                            span {
                                color: $primary-color;
                            }
                        }

                        p {
                            color: inherit;
                            line-height: 1.65;
                            width: 70%;
                        }
                    }

                    .lower-side {
                        img {
                            border-radius: $border-radius;
                            object-fit: cover;
                            position: relative;
                            z-index: 0;
                        }

                        .circles {
                            z-index: 2;
                            position: relative;

                            .circle {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                position: absolute;
                            }

                            .circle-1 {
                                @extend .circle;
                                height: 125px;
                                width: 125px;
                                background-color: $primary-color;
                                margin: 10px;
                                right: 0;
                                top: -20px;
                            }

                            .event-circle-2 {
                                @extend .circle;
                                height: 50px;
                                width: 50px;
                                background: linear-gradient(to top, $primary-color, $secondary-color);
                                margin: 10px;
                                bottom: 0;
                            }

                            .event-circle-3 {
                                @extend .circle;
                                width: 80px;
                                height: 80px;
                                background-color: $secondary-color;
                                margin: 10px;
                                left: 0;
                                top: 250px;
                            }
                        }

                        .overlay {
                            display: flex;
                            gap: 30px;
                            z-index: 1;
                            position: relative;

                            .left .event-image-1 {
                                width: 300px;
                                height: 450px;
                                left: 0;
                                top: 0;
                            }

                            .right {
                                display: flex;
                                flex-direction: column;
                                align-items: baseline;
                                justify-content: flex-end;
                                gap: 30px;

                                .image-2 {
                                    width: 130px;
                                    height: 150px;
                                    bottom: 0;
                                }

                                .image-3 {
                                    width: 200px;
                                    height: 250px;
                                    top: 0;
                                }
                            }
                        }
                    }
                }

                .right-content {
                    width: 90%;

                    .boxes {
                        display: flex;
                        flex-direction: column;
                        gap: 30px;

                        .box {
                            height: fit-content;
                            color: $text-color;
                            width: 75%;
                            background-color: #fff;
                            border-radius: 2rem;
                            padding: 2.5rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            box-shadow: $box-shadow;
                            transition: 0.3s;

                            &:hover {
                                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
                            }

                            a {
                                text-decoration: none;
                                color: #494949;

                                h3 {
                                    margin: 15px 0px 10px 0px;
                                    transition: 0.3s;

                                    &:hover {
                                        color: #f37234;
                                    }
                                }
                            }

                            p {
                                margin: 0px;
                                display: -webkit-box;
                                -webkit-line-clamp: 4;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .d {
                                display: flex;
                                align-items: center;
                                gap: 10px;
                                width: 100%;
                                color: #18619F;
                                font-size: 0.85rem;
                                font-weight: 500;

                                img {
                                    width: 20px;
                                    height: 20px;
                                }
                            }

                        }

                        .center {
                            margin-left: 40px;

                            @media (width <=1024px) {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }

        .classes {
            .b-width-wrapper {
                padding: 100px 0;
                flex-direction: column;
                gap: 100px;

                .content {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    gap: 20px;
                    align-items: center;

                    h3 {
                        font-size: 2.5rem;
                        font-weight: 700;
                        color: $text-color;
                        margin: 0;

                        span {
                            color: $primary-color;
                        }
                    }

                    p {
                        margin: 0;
                        font-size: inherit;
                        width: 50%;
                    }
                }

                .slider {
                    .custom-card {
                        border-radius: $border-radius;
                        box-shadow: $box-shadow;
                        transition: box-shadow 0.3s ease;
                        max-width: 360px;
                        width: 100%;
                        height: 320px;

                        &:hover {
                            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

                            .media {
                                border-top: 4px solid $secondary-color;
                            }

                            .custom-card-title {
                                color: $primary-color;
                            }
                        }

                        .MuiCardMedia-root {
                            border-bottom: 1px solid #ddd;
                        }

                        .media {
                            border-top: 4px solid $primary-color;
                            transition: border-top 0.3s ease;
                        }

                        .custom-card-title {
                            font-size: 1.5rem;
                            font-weight: bold;
                            color: $text-color;
                            transition: color 0.3s ease;
                            height: 100%;
                            text-align: center;
                        }
                    }

                    .images {
                        height: 250px;
                    }
                }
            }
        }

        .birthday-slider {
            background-color: #f4fcff;
            position: relative;
            overflow: hidden;
            padding: 130px 0;

            canvas {
                cursor: default !important;
            }

            .b-width-wrapper {
                display: block;
                position: relative;
                z-index: 2;

                h3 {
                    font-size: 2.5rem;
                    font-weight: 700;
                    color: $text-color;
                    text-align: center;
                    margin: 0;

                    span {
                        color: $primary-color;
                    }
                }

                .birthday-wrapper {
                    display: flex;
                    align-items: center;

                    @media (width <=540px) {
                        justify-content: center;
                    }

                    .none {
                        display: none;
                    }

                    .celebrate {
                        width: 100%;

                        @media (width <=540px) {
                            width: 80% !important;
                        }

                        .slick-arrow {
                            background-color: #18619F;
                            width: 35px;
                            height: 35px;
                            position: absolute;
                            border: none;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;

                            &.slick-prev {
                                left: -30px;
                            }

                            &.slick-next {
                                right: -30px;

                                @media (width <=540px) {
                                    right: -40px;
                                }
                            }
                        }

                        .slick-track {
                            display: flex;
                            gap: 20px;
                            margin-top: 50px;

                            .birthday-card {
                                color: #494949;
                                background-color: #fff;
                                border-radius: 2rem;
                                padding: 2em 0;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
                                transition: 0.3s;

                                video {
                                    width: 150px;
                                    height: 150px;
                                }

                                p {
                                    color: #494949;
                                }

                                .name {
                                    font-weight: 700;
                                }

                                .date {
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }

        .gallery {
            .b-width-wrapper {
                flex-direction: column;
                gap: 50px;

                .text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    gap: 10px;
                    width: 100%;

                    h3,
                    p {
                        margin: 0;
                    }

                    h3 {
                        font-size: 2.5rem;
                        font-weight: 700;
                        color: $text-color;

                        span {
                            color: $primary-color;
                        }
                    }

                    p {
                        width: 60%;
                        color: $text-color;
                    }
                }

                .gallery-images {
                    .pictures {
                        display: flex;
                        gap: 20px;

                        .col {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;

                            img {
                                --f: 1.15;
                                object-fit: cover;
                                border-radius: $border-radius;
                                transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
                                position: relative;

                                &:hover {
                                    transform: scale(var(--f));
                                    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
                                }
                            }
                        }

                        .col-1 {
                            align-items: end;
                            justify-content: center;

                            .school-image-1 {
                                width: 50%;
                                height: auto;
                            }

                            .school-image-2 {
                                width: 12.5rem;
                                /* 200px / 16px */
                                height: 20rem;
                                /* 320px / 16px */
                            }
                        }

                        .col-2 {
                            align-items: start;

                            .school-image-1 {
                                width: 100%;
                                height: 23rem;
                                /* 368px / 16px */
                            }

                            .school-image-2 {
                                width: 10rem;
                                /* 160px / 16px */
                                height: 9.375rem;
                                /* 150px / 16px */
                            }
                        }

                        .col-3 {
                            align-items: start;
                            justify-content: center;

                            .school-image-1 {
                                width: 12.5rem;
                                /* 200px / 16px */
                                height: 18.75rem;
                                /* 300px / 16px */
                            }

                            .school-image-2 {
                                width: 8.125rem;
                                /* 130px / 16px */
                                height: 7.5rem;
                                /* 120px / 16px */
                            }
                        }

                        .col-4 {
                            align-items: start;
                            justify-content: center;

                            .school-image-1 {
                                width: 9.375rem;
                                /* 150px / 16px */
                                height: 12.5rem;
                                /* 200px / 16px */
                            }

                            .school-image-2 {
                                width: 12.5rem;
                                /* 200px / 16px */
                                height: 18.75rem;
                                /* 300px / 16px */
                            }
                        }

                        .col-5 {
                            align-items: start;
                            justify-content: center;

                            .school-image-1 {
                                width: 18.75rem;
                                /* 300px / 16px */
                                height: 15.625rem;
                                /* 250px / 16px */
                            }

                            .school-image-2 {
                                width: 12.5rem;
                                /* 200px / 16px */
                                height: 12.5rem;
                                /* 200px / 16px */
                            }
                        }

                    }
                }
            }
        }

        .wave {
            margin-top: -150px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .b-home-wrapper {
        .b-wrapper {
            .content {
                flex-direction: column;
                gap: 50px;

                .left-side {
                    width: 100%;
                    text-align: center;

                    h1 {
                        font-size: 3rem;
                    }

                    p {
                        padding: 0 20px;
                    }
                }

                .right-side {
                    .circle-1 {
                        height: 100px;
                        width: 100px;
                        right: 0;
                        top: 0;
                    }

                    .circle-2 {
                        height: 40px;
                        width: 40px;
                        top: 100px;
                    }

                    .circle-3 {
                        width: 60px;
                        height: 60px;
                        top: 200px;
                    }

                    .circle-4 {
                        width: 30px;
                        height: 30px;
                        top: 350px;
                        right: 100px;
                    }

                    .image-1 {
                        width: 200px;
                        height: 200px;
                        left: 0;
                        top: 0;
                    }

                    .image-2 {
                        width: 150px;
                        height: 150px;
                        right: 0;
                        top: 200px;
                    }

                    .image-3 {
                        width: 100px;
                        height: 100px;
                        left: 100px;
                        top: 300px;
                    }

                    .image-4 {
                        width: 70px;
                        height: 70px;
                        right: 80px;
                        top: 450px;
                    }
                }
            }

            .about {
                margin-top: 290px;

                .background {
                    .content {
                        .heading {
                            h1 {
                                font-size: 2rem;
                            }

                            p {
                                font-size: 1rem;
                            }
                        }

                        .about-boxes {
                            flex-direction: column;

                            .box,
                            .big-box {
                                width: 95%;
                                padding: 2rem;
                            }

                            .big-box img {
                                width: 7rem;
                                height: 7rem;
                            }

                            a {
                                font-size: 0.8rem;
                                padding: 0.5rem 1rem;
                                height: 2.25rem;
                            }

                            .box img {
                                width: 2rem;
                                height: 2rem;

                            }
                        }
                    }
                }
            }

            .events {
                .b-width-wrapper {
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;

                    .left-images {
                        .upper-side {
                            h1 {
                                font-size: 2rem;
                            }

                            p {
                                width: 100%;
                            }
                        }

                        .lower-side {
                            display: none;
                        }
                    }

                    .right-content {
                        width: 95%;

                        .boxes {
                            width: 100%;
                            justify-content: center;

                            .box {
                                width: 100%;
                                padding: 2rem;
                            }
                        }
                    }
                }
            }

            .gallery {
                .b-width-wrapper {
                    padding-top: 0px;

                    .gallery-images {
                        .pictures {
                            justify-content: center;

                            .col {
                                img {
                                    --f: 1.1;
                                }
                            }

                            .col-1 {
                                .school-image-1 {
                                    width: 80px;
                                    height: 80px;
                                }

                                .school-image-2 {
                                    width: 150px;
                                    height: 200px;
                                }
                            }

                            .col-2 {
                                .school-image-1 {
                                    width: 150px;
                                    height: 250px;
                                }

                                .school-image-2 {
                                    width: 120px;
                                    height: 120px;
                                }
                            }

                            .col-3 {
                                .school-image-1 {
                                    width: 150px;
                                    height: 200px;
                                }

                                .school-image-2 {
                                    width: 100px;
                                    height: 100px;
                                }
                            }

                            .col-4 {
                                .school-image-1 {
                                    width: 100px;
                                    height: 150px;
                                }

                                .school-image-2 {
                                    width: 150px;
                                    height: 200px;
                                }
                            }

                            .col-5 {
                                .school-image-1 {
                                    width: 200px;
                                    height: 150px;
                                }

                                .school-image-2 {
                                    width: 150px;
                                    height: 150px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 820px) {
    .b-home-wrapper {
        .b-wrapper {
            .about {
                margin-top: 350px;
            }

            .gallery {
                .b-width-wrapper {
                    .gallery-images {
                        .pictures {
                            .col {
                                img {
                                    --f: 1.05;
                                }
                            }

                            .col-1 {
                                .school-image-1 {
                                    width: 70px;
                                    height: 70px;
                                }

                                .school-image-2 {
                                    width: 150px;
                                    height: 200px;
                                }
                            }

                            .col-2 {
                                .school-image-1 {
                                    width: 150px;
                                    height: 200px;
                                }

                                .school-image-2 {
                                    width: 95px;
                                    height: 95px;
                                }
                            }

                            .col-3 {
                                .school-image-1 {
                                    width: 130px;
                                    height: 180px;
                                }

                                .school-image-2 {
                                    width: 70px;
                                    height: 70px;
                                }
                            }

                            .col-4 {
                                .school-image-1 {
                                    width: 60px;
                                    height: 85px;
                                }

                                .school-image-2 {
                                    width: 100px;
                                    height: 150px;
                                }
                            }

                            .col-5 {
                                .school-image-1 {
                                    width: 150px;
                                    height: 100px;
                                }

                                .school-image-2 {
                                    width: 100px;
                                    height: 100px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:440px) {
    .b-home-wrapper {
        .b-wrapper {
            .content {
                .left-side {
                    h1 {
                        font-size: 2.5rem;
                    }

                    p {
                        padding: 0 10px;
                    }
                }

                .right-side {
                    display: none;
                }
            }

            .about {
                margin-top: 100px;

                .background {
                    .content {
                        .about-boxes {

                            .box,
                            .big-box {
                                padding: 0.8rem;
                                width: 90%;
                            }
                        }
                    }
                }


            }

            .events {
                .b-width-wrapper {
                    .left-images {
                        .upper-side {
                            h1 {
                                font-size: 1.5rem;
                            }
                        }
                    }

                    .right-content {
                        .boxes {
                            .box {
                                width: 96%;
                                padding: 1rem;
                            }
                        }
                    }
                }
            }

            .classes {
                .b-width-wrapper {
                    .content {
                        p {
                            width: 100%;
                        }
                    }
                }
            }

            .gallery {
                .b-width-wrapper {
                    .text {
                        p {
                            width: 100%;
                        }
                    }

                    .gallery-images {
                        .pictures {
                            flex-direction: column;

                            .col-1 .school-image-1,
                            .col-1 .school-image-2,
                            .col-2 .school-image-1,
                            .col-2 .school-image-2,
                            .col-3 .school-image-1,
                            .col-3 .school-image-2,
                            .col-4 .school-image-1,
                            .col-4 .school-image-2,
                            .col-5 .school-image-1,
                            .col-5 .school-image-2 {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}