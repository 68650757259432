.b-header-wrapper {
    display: flex;
    justify-content: center;

    .b-width-wrapper {
        width: 100%;
        flex-direction: column;

        .b-navbar {
            display: flex;
            padding: 20px 0px;
            border-bottom: 3px solid #91b7d6;
            align-items: center;

            .logo {
                width: 50%;

                img {
                    width: 100px;
                    height: auto;
                }
            }

            .menu-icon {
                display: none;
                cursor: pointer;
                transition: opacity 0.3s ease-in-out;
                
                img {
                    width: 30px;
                    height: 30px;
                }
            }

            .menu-icon.fade-out {
                opacity: 0;
            }

            .menu-icon.fade-in {
                opacity: 1;
            }

            .headings {
                width: 100%;
                display: flex;
                align-items: center;

                ul {
                    display: flex;
                    gap: 30px;
                    padding: 0px;

                    li {
                        list-style-type: none;

                        &:nth-child(2) {

                            span {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                color: #125E9E;
                                text-decoration: none;
                                font-weight: 500;
                                transition: color 0.3s;
                                font-size: 1.1rem;
                                position: relative;
                                cursor: pointer;

                                .rotate {
                                    transition: transform 0.3s ease-in-out;
                                    transform: rotate(0deg);
                                
                                    &.open {
                                        transform: rotate(180deg);
                                    }
                                }

                                &::before {
                                    content: "";
                                    position: absolute;
                                    bottom: -6px;
                                    left: 0;
                                    width: 0;
                                    height: 4px;
                                    background-color: #fdc12a;
                                    border-radius: 1rem;
                                    opacity: 0;
                                    visibility: hidden;
                                    transition: all 0.4s ease-in-out;
                                }

                                &:hover {
                                    color: #F37539;

                                    &::before {
                                        background-color: #f37234;
                                        width: 2rem;
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }

                                &.active {
                                    color: #125E9E;

                                    &::before {
                                        width: 2rem;
                                        opacity: 1;
                                        visibility: visible;
                                        background-color: #fdc12a;
                                    }

                                    &:hover {
                                        color: #F37539;

                                        &::before {
                                            background-color: #f37234;
                                        }
                                    }
                                }
                            }

                            .dropdown-menu {
                                display: none;
                                position: absolute;
                                background-color: #ffffff;
                                border: 1px solid #ddd;
                                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                                min-width: 200px;
                                border-radius: 6px;
                                z-index: 1000;

                                li {
                                    list-style: none;
                                    padding: 8px 16px;
                                    cursor: pointer;

                                    &:hover {
                                        background-color: #f5f5f5;
                                    }

                                    a {
                                        text-decoration: none;

                                        &.active {
                                            color: #125E9E;
            
                                            &::before {
                                                width: 2rem;
                                                opacity: 1;
                                                visibility: visible;
                                                background-color: #fdc12a;
                                            }
            
                                            &:hover {
                                                color: #F37539;
            
                                                &::before {
                                                    background-color: #f37234;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.open {
                                    display: block;
                                }
                            }
                        }

                        a {
                            color: #125E9E;
                            text-decoration: none;
                            font-weight: 500;
                            transition: color 0.3s;
                            font-size: 1.1rem;
                            position: relative;

                            &::before {
                                content: "";
                                position: absolute;
                                bottom: -6px;
                                left: 0;
                                width: 0;
                                height: 4px;
                                background-color: #fdc12a;
                                border-radius: 1rem;
                                opacity: 0;
                                visibility: hidden;
                                transition: all 0.4s ease-in-out;
                            }

                            &:hover {
                                color: #F37539;

                                &::before {
                                    background-color: #f37234;
                                    width: 2rem;
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }

                            &.active {
                                color: #125E9E;

                                &::before {
                                    width: 2rem;
                                    opacity: 1;
                                    visibility: visible;
                                    background-color: #fdc12a;
                                }

                                &:hover {
                                    color: #F37539;

                                    &::before {
                                        background-color: #f37234;
                                    }
                                }
                            }
                        }
                    }
                }

                &.hide {
                    display: none;
                }
            }

            .social-links {
                width: 20%;
                display: flex;
                gap: 15px;
                justify-content: flex-end;
                align-items: center;

                .link-1,
                .link-2,
                .link-3,
                .link-4 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 2px;
                    border-radius: 50%;
                    width: 36px;
                    height: 36px;
                    cursor: pointer;
                    transition: transform 0.3s ease-in-out;

                    &:hover {
                        transform: translateY(-5px);
                    }

                    img {
                        width: 18px;
                        height: 18px;
                    }
                }

                .link-1 {
                    border: 2px solid #1877F2;
                }

                .link-2 {
                    border: 2px solid #2cb642;
                }

                .link-3 {
                    border: 2px solid #ee2a7b;
                }

                .link-4 {
                    border: 2px solid #FF0000;
                }
            }
        }
    }

    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        width: 250px;
        height: 100%;
        background-color: #f37234;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;

        &.open {
            transform: translateX(0);
        }

        .close-icon {
            display: flex;
            justify-content: flex-end;
            padding: 20px;
            cursor: pointer;

            img {
                width: 25px;
                height: 25px;
            }
        }

        ul {
            list-style: none;
            padding: 0px;

            li {
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                padding: 1rem 1.25rem;

                a {

                    color: #fff;
                    text-decoration: none;
                    font-weight: 500;
                    font-size: 1.1rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .b-header-wrapper {
        .b-width-wrapper {
            .b-navbar {
                justify-content: space-between;

                .menu-icon {
                    display: block;
                }

                .headings {
                    display: none;
                }

                .social-links{
                    display: none;
                }
            }
        }
    }
}
