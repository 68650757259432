.b-curriculum-wrapper{

    .content{
        flex-direction: column;

        h2{
            text-align: center;
            color: #494949;
            font-size: 40px;

            .b-1{
                background: linear-gradient(to right, red,  #aa4203);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .b-2{
                background: linear-gradient(to right, #87ceeb, #8cbed6);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .b-3{
                background: linear-gradient(to right, #ff99cc, purple);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        p{
            text-align: justify;
            color: #494949;
            line-height: 26px;
        }

        .data{
            display: flex;
            margin-top: 30px;
            gap: 30px;

            .left{
                width: 100%;

                h3{
                    color: #494949;
                    font-size: 28px;

                    b{
                        color: #FF7A00;
                    }
                }

                p{
                    color: #494949;
                    line-height: 26px;
                    text-align: justify;
                }

            }

            .image{
                width: 100%;

                img{
                    width: 100%;
                   
                    border-radius: 20px;
                }
            }
        }

        .section-2{
            margin-top: 40px;
            h3{
                color: #494949;
                font-size: 28px;
            }
            p{
                text-align: justify;
                color: #494949;
                line-height: 26px;
            }
        }
    }
}
