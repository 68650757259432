.b-values-wrapper{

    .content{
        flex-direction: column;

        .data{
            display: flex;
            margin-top: 30px;
            gap: 30px;

            .left{
                width: 100%;

                h2{
                    color: #494949;
                    font-size: 32px;

                    b{
                        color: #FF7A00;
                    }
                }

                strong{
                    color: #494949;
                    text-align: justify;
                }

                ul{
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    margin-top: 30px;
                    color: #494949;
                }
            }

            .image{
                width: 100%;

                img{
                    width: 100%;
                   
                    border-radius: 20px;
                }
            }
        }
    }
}
