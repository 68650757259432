.b-privacy-wrapper{

    .content{
        flex-direction: column;

        h2{
            font-size: 1.5rem;
            text-align: center;
            color: #494949;
            font-size: 35px;
        }

        .data{
            display: flex;
            flex-direction: column;

            h3{
                font-size: 25px;
                color: #494949;
                margin: 0px;
                padding-top: 30px;
            }

            p{
                color: #494949;
                text-align: justify;
                line-height: 26px;

                .email{
                    text-decoration: none;
                    color: #f37234;
                    margin-left: 2px;
                }
            }

            ul{
                color: #494949;
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin: 0px;

                li{
                    line-height: 26px;
                    text-align: justify;
                }
            }
        }
    }
}
