.b-jobs-wrapper {

    .wrapper {
        display: flex;
        flex-direction: column;

        h1{
            color: #ff6b00;
            font-size: 2.5rem;
            margin: 20px 0;
        }

        .b-jobs-containers {
            display: flex;
            gap: 80px;

            @media screen and (max-width: 1024px) {
                gap: 60px;
            }

            @media screen and (max-width: 914px) {
                gap: 40px;
            }

            @media screen and (max-width: 680px) {
                gap: 20px;
                flex-direction: column;
            }

            .images {
                width: 100%;

                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    border-radius: 6px;
                }

                .upper-image {
                    margin-bottom: 10px;
                    width: 100%;
                }

                .lower-image {
                    display: flex;
                    gap: 10px;
                    width: 100%;

                    img {
                        width: 49%;
                        height: auto;
                        border-radius: 6px;
                        object-fit: cover;
                    }
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                gap: 20px;
                height: auto;
                overflow: auto;

                .b-job {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    width: 90%;
                    border: 1px solid #e0e0e0;
                    border-radius: 1rem;
                    padding: 20px;
                    transition: box-shadow 0.3s;

                    @media screen and (max-width: 890px) {
                        width: 75%;
                    }

                    @media screen and (max-width: 680px) {
                        width: 90%;
                    }

                    @media screen and (max-width: 500px) {
                        width: 85%;
                    }

                    &:hover {
                        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
                    }

                    .b-read-more{
                        text-decoration: none;
                        color: #3F7BA3;
                        text-transform: capitalize;
                        transition: all 0.3s ease;
                        font-size: 0.825rem;

                        &:hover {
                            color: #ff6b00;
                        }
                    }

                    a {
                        text-decoration: none;
                        cursor: pointer;
                        width: fit-content;

                        h2 {
                            color: #494949;
                            margin: 0;
                            transition: color 0.3s;
                            text-transform: capitalize;

                            &:hover {
                                color: #ff6b00;
                            }

                            @media screen and (max-width: 940px) {
                                font-size: 1rem;
                            }
                        }
                    }

                    p {
                        color: #494949;
                        margin: 0;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: auto;

                        @media screen and (max-width: 940px) {
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }
    }
}