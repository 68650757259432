.b-events-wrapper {

    .content {
        h1 {
            color: #ff6b00;
            font-size: 2.5rem;
            text-align: left;
            margin: 20px 0;
            width: 100%;
        }

        width: 100%;
        flex-direction: column;
        align-items: center;

        .load-more-btn {
            width: fit-content;
            background-color: #145e9d;
            padding: 0.75rem 1rem;
            font-size: 1rem;
            color: #fff;
            font-weight: 500;
            border-radius: 1rem;
            border: none;
            cursor: pointer;
            transition: 0.5s ease-in-out;

            &:hover {
                background-color: #f37234;
            }
        }

        .cards {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            width: 100%;

            .card {

                gap: 20px;
                display: flex;
                width: 43%;
                background-color: #fff;
                border-radius: 1rem;
                padding: 2.3rem;
                text-align: center;
                box-shadow: 0px 0px 0.5rem 0 #c9e2f8;
                transition: 0.3s;

                &:hover {
                    box-shadow: 0px 0px 0.5rem 0.2rem #c9e2f8;
                }

                .image-side {
                    width: 50%;
                    height: 200px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 1rem;
                        object-fit: cover;
                    }
                }

                .content-side {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;


                    .calendar {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 0.85rem;
                        font-weight: 500;
                        color: #135e9e;

                        .circle {
                            background-color: #c9e2f8;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 10px;
                            border-radius: 50%;

                            img {
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }

                    a {
                        text-decoration: none;
                    }

                    p {
                        color: #494949;
                        font-weight: 400;
                        font-size: 1rem;
                        margin: 10px 0 0 0;
                        text-align: left;
                        display: -webkit-box;
                        -webkit-line-clamp: 5;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    h1 {
                        color: #494949;
                        font-size: 1.25rem;
                        font-weight: 600;
                        margin: 1.5rem 0 0 0;
                        cursor: pointer;
                        transition: 0.3s;

                        &:hover {
                            color: #f37234;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .b-events-wrapper {
        .content {
            .cards {
                .card {
                    width: 100%;
                    padding: 1.5rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .b-events-wrapper {
        .content {
            .cards {
                .card {
                    width: 100%;
                    padding: 1.5
                }
            }
        }
    }
}

@media only screen and (max-width: 440px) {
    .b-events-wrapper {
        .content {
            .cards {
                .card {
                    width: 100%;
                    padding: 1.5rem;

                    .image-side {
                        width: 100%;
                        height: 200px;
                    }

                    .content-side {
                        .calendar {
                            font-size: 0.75rem;
                        }

                        p {
                            font-size: 0.9rem;
                        }

                        h1 {
                            text-align: left;
                            font-size: 1.1rem;
                        }
                    }
                }
            }
        }
    }
}