
@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(50px); 
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


.b-addmission-wrapper {

    padding-top: 50px;
   

    .section-1 {

        .content {
            display: flex;
            gap: 35px;
            
            .left-side{
                width: 100%;

                span{
                    color: #f37234;
                }

                h2, p{
                    color: #494949;
                }

                p{
                    text-align: justify;
                    line-height: 26px;
                    font-weight: 400;
                }
            }

            .right-side{
                width: 100%;

                img{
                    width: 100%;
                    height: auto;
                    border-radius: 20px;
                    animation: fadeUp 1s ease-out forwards; 
                }
            }
        }
    }

    .section-2{
        margin-top: 80px;

        .content{
            display: flex;
            gap: 35px;

            .left-side{
                width: 100%;

                img{
                    width: 100%;
                    height: auto;
                    border-radius: 20px;
                    animation: fadeUp 1s ease-out forwards; 
                }
            }

            .right-side{
                width: 100%;

                a{
                    color: #f37234;
                    text-decoration: none;
                    font-weight: 700;
                }

                h2{
                    color: #f37234;
                }

                p{
                    text-align: justify;
                    line-height: 26px;
                    font-weight: 400;
                    color: #494949;
                }
               
            }
        }
    }

    .section-3{
        margin-top: 80px;

        .content{
            display: flex;
            gap: 35px;

            .left-side{
                width: 100%;

                h2{
                    color: #f37234;
                }

                 h3, li{
                    color: #494949;
                }

                li{
                    text-align: justify;
                    line-height: 26px;
                    font-weight: 400;
                }
            }

            .right-side{
                width: 100%;

                img{
                    width: 100%;
                    height: auto;
                    border-radius: 20px;
                    animation: fadeUp 1s ease-out forwards; 
                }
            }
        }
    }

    .section-4{
        margin-top: 80px;

        .content{
            display: flex;
            flex-direction: column;
            gap: 35px;
            
            .heading{
                display: flex;
                flex-direction: column;
                text-align: center;

                h2{
                    color: #f37234;
                }
                
                strong{
                    color: #494949;
                }
            }

            .boxes{
                display: flex;
                gap: 20px;

                .box{
                    background-color: #fff;
                    padding: 3rem;
                    border-radius: 20px;
                    transition: 0.5s;
                    box-shadow: 0 0 0.5rem 0rem #c9e2f8;
                    animation: fadeUp 1s ease-out forwards; 

                    &:hover{
                        box-shadow: 0 0 0.5rem 0.2rem #c9e2f8;
                    }

                    img{
                        width: 30px;
                        height: 30px;
                    }

                    h3{
                        color: #f37234;
                        margin-top: 0px;
                    }

                    ul{
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                    }
                 
                    li{
                        text-align: justify;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 820px){
    .b-addmission-wrapper {
        .section-1 {
            .content {
                flex-direction: column;
            }
        }

        .section-2 {
            .content {
                flex-direction: column;
            }
        }

        .section-3 {
            .content {
                flex-direction: column;
            }
        }

        .section-4 {
            .content {
                flex-direction: column;

                .boxes{
                    flex-direction: column;
                }
            }
        }
    }
}