.b-refund-wrapper{

    .content{
        flex-direction: column;

        h2{
            font-size: 1.5rem;
            text-align: center;
            color: #494949;
            font-size: 35px;
        }

        .data{
            display: flex;
            flex-direction: column;

            h3{
                font-size: 25px;
                color: #494949;
                margin: 10px 0px 0px 0px;
            }

            ol{
                color: #494949;
                display: flex;
                flex-direction: column;
                gap: 15px;

                li{
                    line-height: 26px;
                    text-align: justify;
                }
            }
        }
    }
}
